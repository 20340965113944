import './accordion.scss';
import * as React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { AccordionTitle } from './accordion-title/accordion-title';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import { AccordionContent } from '../page';

const useStyles = makeStyles({
  root: {
    background: 'aliceblue'
  }
});

type Props = {
  accordion_content: AccordionContent
}

export default function InfoAccordion(props: Props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(false);
  
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
      <>
        <Accordion classes={{ root: classes.root }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <AccordionTitle 
              title={'Annahme'}
              days={['Dienstag']}
              times={['14:00 - 18:00 Uhr']}
            />
          </AccordionSummary>
          <AccordionDetails>
            <div 
              className="accordion-content" 
              dangerouslySetInnerHTML={{__html: props.accordion_content.Annahme }}>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.root }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <AccordionTitle 
              title={'Verkauf'}
              days={['Mittwoch', 'Donnerstag']}
              times={['14:00 - 18:00 Uhr', '08:30 - 11:00 Uhr']}
            />
          </AccordionSummary>
          <AccordionDetails>
          <div 
              className="accordion-content" 
              dangerouslySetInnerHTML={{__html: props.accordion_content.Verkauf }}>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion classes={{ root: classes.root }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <AccordionTitle 
              title={'Rückgabe und Auszahlung'}
              days={['Donnerstag']}
              times={['16:00 - 18:00 Uhr']}
            />
          </AccordionSummary>
          <AccordionDetails>
          <div 
              className="accordion-content" 
              dangerouslySetInnerHTML={{__html: props.accordion_content.Rueckgabe }}>
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }