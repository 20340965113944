
import './documents.scss';
import React, { FC } from 'react';
import { Document } from '../page';
import { Button } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import FileService from './../../services/fileService'

type DocumentProps = {
    documents: Array<Document>
};

export default function Documents(props: DocumentProps) {

    const onButtonClicked = (document: Document) => {
        FileService.downloadFile(document.document, document.name);
    }

    return (
        <>
            {props.documents.map(document => (
                <div className="button-container">
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onButtonClicked(document)}
                    startIcon={<DownloadIcon />}
                    >
                        {document.name}
                    </Button>
                </div>
            ))}
        </>
    );
}