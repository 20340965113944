import './page.scss';
import React, { useState, useEffect } from 'react';
import butter from '../butter-client.js';
import InfoAccordion from './accordion/accordion';
import { ImageCarousel } from './image-carousel/image-carousel';
import Documents from './documents/documents';
import DOMPurify from 'dompurify';

export type AccordionContent = {
    Annahme: string,
    Verkauf: string,
    Rueckgabe: string
}

export type Document = {
    document: string,
    name: string
}

type Image = {
    image: string,
    caption: string,
    link: string
}

type Fields = {
    information: string,
    next_date: string,
    next_next_date: string,
    welcome_text: string,
    accordion_content: AccordionContent,
    document_block: Array<Document>,
    images: Array<Image>
};

export function Page() {
    const [data, setData] = useState<Fields>({
        information: "",
        next_date: "",
        next_next_date: "",
        welcome_text: "",
        accordion_content: {
            Annahme: "",
            Verkauf: "",
            Rueckgabe: ""
        },
        document_block: [],
        images: []
    });

    useEffect(() => {
        const fetchButterData = async () => {
            const page = await butter.page.retrieve('*', 'boerse_landing');
            const response = page.data.data.fields;
            const fields: Fields = {
                information: DOMPurify.sanitize(response.information),
                next_date: DOMPurify.sanitize(response.next_date),
                next_next_date: DOMPurify.sanitize(response.next_next_date),
                welcome_text: DOMPurify.sanitize(response.welcome_text),
                accordion_content: {
                    Annahme: DOMPurify.sanitize(response.annahme),
                    Verkauf: DOMPurify.sanitize(response.verkauf),
                    Rueckgabe: DOMPurify.sanitize(response.rueckgabe)
                },
                document_block: response.document_block,
                images: response.image_gallery.gallery_item
            };
            setData(fields);
        }
        fetchButterData();
    }, []);

    return (
    <div className="page">
        <div className="headline">
            {data.information && (
                <div className="isa isa_warning">
                    <span>{data.information}</span>
                </div>
            )}
            <div className="isa isa_info">
                <span><strong>Nächste Börse(n)</strong></span>
                <span>{data.next_date}</span>
                <span>{data.next_next_date}</span>
            </div>
        </div>

        <div className="welcome">
            <div className="text" dangerouslySetInnerHTML={{__html: data.welcome_text }}></div>
        </div>

        <div className="info">
            <div className="news">
                <div className="news-title">Wie folgt sind wir an den Börsentagen für Sie da:</div>
                <InfoAccordion accordion_content={data.accordion_content}/>
            </div>
            <div className="documents">
                <div className="documents-title">Dokumente</div>
                <Documents documents={data.document_block}/>
            </div>
        </div>

        <div className="images">
            <ImageCarousel imgSources={data.images.map(image => image.image)} />
        </div>
    </div>
    )
  }