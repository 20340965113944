import './accordion-title.scss';
import { FC } from 'react';

type Props = {
  title: string,
  days: string[],
  times: string[]
};

export const AccordionTitle: FC<Props> = (props: Props) => (
  <div className="accordion-summary-container">
      <div className="accordion-summary-title">
        {props.title}
      </div>
      <div className="accordion-summary-daytime">
        <div className="accordion-summary-day">
          {props.days.map(day => (
            <span>{day}</span>
          ))}
        </div>
        <div className="accordion-summary-time">
          {props.times.map(time => (
            <span>{time}</span>
          ))}
        </div>
      </div>
  </div>
);