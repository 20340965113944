import './contribution.scss';
import React, { FC } from 'react';

const Contribution: FC = () => {
  return (
    <div className="footer">
      <div className="contribution">
        <span>erstellt mit / built with</span>
        <a href="https://buttercms.com">
            <img src={'/butter-logo.png'} />
        </a>
      </div>
      <div className="contact">
        Kontakt: Nicole Karrer, <a href= "mailto:nika71@hotmail.ch">nika71@hotmail.ch</a>
      </div>
    </div>
  );
}

export default Contribution;