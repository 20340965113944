
import './header.scss';

export function Header(){
    return (
        <div className="header">
            <img src={'/logo.png'} />
            <h1>Kinderkleider- und Spielzeugbörse Seen</h1>
        </div>
    );
} 