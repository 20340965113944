import React, { FC } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

type ImageCarouselProps = {
    imgSources: Array<string>;
};

export const ImageCarousel: FC<ImageCarouselProps> = (props: ImageCarouselProps) => (
    <div>
        <h2>Impressionen</h2>
        <Carousel>
            {props.imgSources.map(imgSrc => (
                <div>
                    <img src={imgSrc} />
                </div>
            ))}
        </Carousel>
    </div>
);