import './App.scss';
import React from 'react';
import { Header } from '../src/components/header/header';
import { Page } from '../src/components/page';
import Contribution from '../src/components/contribution/contribution';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
     primary: {
        main: "#0044a8"
      }
    }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Header />
        <Page />
        <Contribution />
      </ThemeProvider>
    </div>
  );
}

export default App;
