import { saveAs } from 'file-saver';

export default class FileService {
    static downloadFile(url: string, name: string): void {
        saveAs(url, name);
        
        // currently not used
        // var oReq = new XMLHttpRequest();
        // // The Endpoint of your server 
        // var URLToPDF = url;

        // // Configure XMLHttpRequest
        // oReq.open("GET", URLToPDF, true);

        // // Important to use the blob response type
        // oReq.responseType = "blob";

        // // When the file request finishes
        // // Is up to you, the configuration for error events etc.
        // oReq.onload = function() {
        //     // Once the file is downloaded, open a new window with the PDF
        //     // Remember to allow the POP-UPS in your browser
        //     var file = new Blob([oReq.response], { 
        //         type: 'application/pdf' 
        //     });
            
        //     // Generate file download directly in the browser !
        //     // saveAs(file, name);
        // };

        // oReq.send();
    }
}
